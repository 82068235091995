import React from 'react';
import './styles.css';

const Header = () => (
  <header className='home-header'>
    <h2>O Gringo Agora Sou Eu</h2>
    
    <h1><span>“</span> Blog <span>”</span></h1>
    
    <p>
    {/* <div id="container-da-imagem">
    <h2><img src='/assets/images/LOGOOGRINGO.fw.png' alt="logo" class="center"/></h2>
    </div> */}
    </p>
    <img src='/assets/images/LOGOOGRINGO.fw.png' alt="logo" class="center"/>
  </header>
);

export default Header;
